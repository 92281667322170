import React, { useRef } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css/core'
import styles from './splideHeader.module.scss'

function SplideHeader({ data }: { data: string[] }) {
    const splide = useRef<Splide | null>(null)


    // const startAutoplay = () => {
    //     splide.current.splide.Components.Autoplay.play()
    // }

    // const stopAutoplay = () => {
    //     splide.current.splide.Components.Autoplay.pause()
    // }

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.splide} ref={splide}
            // onMouseEnter={startAutoplay} onMouseLeave={startAutoplay}

            options={{
                type: 'fade',
                perMove: 1,
                pagination: false,
                arrows: true,
                interval: 5000,
                autoplay: false,
                speed: 5000,
                rewind: true,
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.left}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.right}`} />
            </div>
            <SplideTrack className={styles.track}>
                {data?.map((item, index) => {
                    return (
                        <SplideSlide key={index} className={`${styles.element}`}>
                            <div className={`${styles.img}`} style={{ backgroundImage: `url(${item})` }} />
                            {/* <img className={`${styles.img}`} src={item} /> */}
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default SplideHeader