import React, { useEffect, useRef, useState } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import '@splidejs/react-splide/css/core'
import styles from './splideBlogLatest.module.scss'
import useWindowSize from '../../../Helpers/useWindowSize';
import { Link } from 'react-router-dom';

interface BlogData {
    title: string;
    subtitle: string;
    img: string;
}

function SplideBlogLatest({ data }: { data: BlogData[] }) {
    const splide = useRef<Splide | null>(null)
    const [indexActive, setIndexActive] = useState(2)
    const size = useWindowSize()


    // const startAutoplay = () => {
    //     splide.current.splide.Components.Autoplay.play()
    // }

    // const stopAutoplay = () => {
    //     splide.current.splide.Components.Autoplay.pause()
    // }

    function makeActive(splide: any, index: number, prev: number, dest: number) {

        console.log("index ", index)
        // console.log("index ", prev)

        let perPage = splide.options.perPage

        const firstElement = document.querySelectorAll('[data-blog-slider-latest="0"]')

        if (perPage === 5) {
            const element1 = document.querySelectorAll('[data-blog-slider-latest="1"]')
            const element2 = document.querySelectorAll('[data-blog-slider-latest="2"]')
            const element3 = document.querySelectorAll('[data-blog-slider-latest="3"]')
            // && prev === 4
            if (index === 0) {
                setTimeout(() => {
                    element2.forEach((item) => {
                        item.classList.add(styles.active)
                    })
                }, 30)
                setIndexActive(index + 2)
            }
            if (index === 3) {
                firstElement.forEach((item) => {
                    item.classList.add(styles.active)
                })
                element1.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element2.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element3.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                setIndexActive(index + 2)
            } else if (index === 4) {
                firstElement.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element2.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element1.forEach((item) => {
                    item.classList.add(styles.active)
                })
                setIndexActive(index + 2)
            } else {
                firstElement.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element1.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                element2.forEach((item) => {
                    item.classList.remove(styles.active)
                })
                setIndexActive(index + 2)
            }
        }
        else if (perPage === 3) {
            if (index === data.length - 1) {
                firstElement.forEach((item) => {
                    item.classList.add(styles.active)
                })
                setIndexActive(index + 2)
            } else {

            }
        }
    }

    // TODO: add function to maching width to active element
    useEffect(() => {
        if (size.width <= 1300) {
            setIndexActive(1)
        }
    }, [size.width])

    return (
        <Splide hasTrack={false} aria-label="..." className={styles.splide} ref={splide}
            // onMouseEnter={startAutoplay} onMouseLeave={startAutoplay}
            onMove={(splide, index, prev, dest) => { makeActive(splide, index, prev, dest) }}
            options={{
                type: 'loop',
                perMove: 1,
                perPage: 5,
                pagination: false,
                arrows: true,
                interval: 5000,
                // autoplay: true,
                speed: 600,
                rewind: true,
                breakpoints: {
                    1300: {
                        perPage: 3
                    }
                }
            }}
        >
            <div className="splide__arrows">
                <button className={`splide__arrow splide__arrow--prev ${styles.arrow} ${styles.left}`} />
                <button className={`splide__arrow splide__arrow--next ${styles.arrow} ${styles.right}`} />
            </div>
            <SplideTrack className={styles.track}>
                {data?.map((item, index) => {
                    return (
                        <SplideSlide key={index} className={`${styles.element} ${styles.column} ${indexActive === index ? styles.active : ''}`} data-blog-slider-latest={index}>
                            <Link to={"#"} className={`${styles.contentElement} ${styles.column}`}>
                                <div className={styles.containerImg}>
                                    <div className={`${styles.img}`} style={{ backgroundImage: `url(${item.img})` }} />
                                </div>
                                <div className={`${styles.containerDescription} ${styles.column}`}>
                                    <h2 className={styles.titleElement}>{item.title}</h2>
                                    <p className={styles.textElement}>{item.subtitle}</p>
                                    <div className={styles.textMore}>
                                        <span className={styles.text}>wiecej</span>
                                    </div>
                                </div>
                            </Link>
                        </SplideSlide>
                    )
                })}
            </SplideTrack>
        </Splide>
    )
}

export default SplideBlogLatest