import { NavLink } from "react-router-dom";
import styles from "./header.module.scss"

function Header(){
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.left}>
                    <NavLink to={"/"} className={({ isActive }) => `${styles.elementMenu} ${isActive ? styles.active : ''}`}><span className={styles.text}>Moja strona</span></NavLink>
                </div>
                <div className={styles.middle}>
                    <div className={styles.conatinerInput}>
                        <input className={styles.input}></input>
                    </div>
                </div>
                <div className={styles.right}>
                    <NavLink to={"/blog"} className={({ isActive }) => `${styles.elementMenu} ${isActive ? styles.active : ''}`}><span className={styles.text}>Blog</span></NavLink>
                </div>
            </div>
        </div>
    )
}

export default Header