import { useEffect, useState } from "react";
import styles from "./mainPage.module.scss"
import Loader from "../../Components/Loader/loader";
import SplideHeader from "../../Components/Splide/Header/splideHeader";
import img1 from '../../assets/img/Header/splide/splide1.png'
import img2 from '../../assets/img/Header/splide/splide2.png'
import img3 from '../../assets/img/Header/splide/splide3.png'
import img4 from '../../assets/img/Header/splide/splide4.png'
import img5 from '../../assets/img/Header/splide/splide5.png'
import SplideBlogLatest from "../../Components/Splide/Blog/Latest/splideBlogLatest";

export function MainPage({ isLong = true }) {
    const [data, setData] = useState(false)
    const allImg = [img1, img2, img3, img4, img5]
    const blogData = [
        {
            title: "Introduction",
            subtitle: "This is the subtitle for the first blog post. It provides a brief summary or teaser for what the reader can expect. It provides a brief summary or teaser for what the reader can expect.",
            img: img1
        },
        {
            title: "The Journey Begins",
            subtitle: "Here is the subtitle for the second post, offering some insight into the content and engaging the reader's curiosity.",
            img: img2
        },
        {
            title: "Exploring New Horizons in Technology",
            subtitle: "This subtitle for the third post gives a sneak peek into the article, aiming to draw attention and provide useful context and aiming to draw attention and provide useful context.",
            img: img3
        },
        {
            title: "Understanding the Basics of Programming And Understanding the Basics of Programming",
            subtitle: "In this fourth post's subtitle, we explore key points in a concise manner, encouraging the reader to dive deeper into the topic.",
            img: img4
        },
        {
            title: "Mastering the Art of Problem Solving",
            subtitle: "The subtitle for the fifth post adds more details about the main topic, sparking interest and inviting further reading.",
            img: img5
        }
    ];
    
    useEffect(() => {
        setTimeout(() => {
            setData(true)
        }, 3);
    }, [])

    return (
        <>
            <Loader data={data} isLong={isLong} />
            <div className={styles.container}>

                <div className={styles.content}>
                    <div className={`${styles.column} ${styles.containerTop}`}>
                        <div className={styles.containerBotBlogSlider}>
                            <SplideHeader data={allImg} />
                        </div>
                        <h2>Prace techniczne</h2>
                    </div>
                    <div className={`${styles.column} ${styles.containerBot}`}>
                        <div className={styles.containerBotBlogSlider}>
                            <SplideBlogLatest data={blogData} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MainPage