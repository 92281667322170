import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from '../Header/header';
import MainPage from '../MainPage/mainPage';
import Blog from '../Blog/blog';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route
          path="/:lang(pl|en)"
          element={
            <>
              <Header />
              <div>1</div>
            </>
          }
        /> */}
        <Route
          path="/"
          element={
            <>
              <Header />
              <MainPage />
            </>
          }
        />
        <Route
          path="/blog"
          element={
            <>
              <Header />
              <Blog />
            </>
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
